import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Charity } from '@domains';

@Component({
  selector: 'app-categorize-help-dialog',
  templateUrl: './categorize-help-dialog.component.html',
  styleUrls: ['./categorize-help-dialog.component.scss'],
})
export class CategorizeHelpDialogComponent {
  @HostBinding('style.display') display = 'flex';
  @HostBinding('style.height') height = '100%';
  constructor(
    public reference: MatDialogRef<CategorizeHelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: Charity
  ) {}
}
