<ng-container *ngIf="design" [ngTemplateOutlet]="DESIGN_2"></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    [charity]="charity"
    [territory]="organization"
    [title]="
      charity?.meta?.onlySmallItems
        ? 'Choose the number of bags or boxes for pickup.'
        : 'Choose the items you\'ll be donating.'
    "
    [subtitle]="
      charity?.meta?.onlySmallItems
        ? 'Up to 5 bags or boxes included for ' +
          (pricing.base + pricing.small * 5 | currency) +
          ' fee.'
        : 'If you need help categorizing your items, click on the button below.'
    "
    [hasBack]="hasBack"
    (onBack)="back()"
  >
    <button class="primary-btn filled-btn" (click)="categorizeHelp()">
      Help Me Categorize
    </button>
  </app-page-header>
  <div class="page-content donation-details" fxLayout="column">
    <div
      class="donation-info step-content"
      fxLayoutAlign="center center"
      fxLayoutAlign.xs="center start"
      fxFlex
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <div
          class="donation-spec"
          fxLayout="column"
          [fxLayout.gt-sm]="onlySmall ? 'column' : 'row'"
          fxLayoutGap="20px"
          [fxLayoutGap.gt-sm]="onlySmall ? '0px' : '30px'"
          *ngIf="specification"
        >
          <div fxLayout="column" fxLayoutGap="0" fxLayoutGap.lt-md="20px">
            <app-donation-spec-item
              *ngIf="!onlySmall"
              (specChanged)="onSpecificationChange($event)"
              [count]="specification.xlarge"
              [key]="'xlarge'"
              itemTitle="Extra Large item"
              subtitle="2+ person lift / special equipment"
              description="Appliances, entertainment centers, china cabinets, wardrobes, large slabs of counter top, sectionals (each piece counts separately), dining room tables for 8 or more people and other items that should be carried by more than 2 people or require special equipment."
              icon="xl"
              fxFlex.xs="50"
              [disabled]="specialIds.includes(this.charity?.id || '')"
              [leadId]="leadId"
              [activity]="activity.DONATION_SPEC_XL"
              [editable]="true"
            >
            </app-donation-spec-item>
            <app-donation-spec-item
              *ngIf="!onlySmall"
              (specChanged)="onSpecificationChange($event)"
              [count]="specification.large"
              [key]="'large'"
              itemTitle="Large item"
              subtitle="2-person lift"
              description="Dinner tables, couches, dressers, side boards, executive desks, tall book cases and other items that should be carried by 2 people."
              icon="lg"
              fxFlex.xs="50"
              [disabled]="specialIds.includes(this.charity?.id || '')"
              [leadId]="leadId"
              [activity]="activity.DONATION_SPEC_LG"
              [editable]="true"
            >
            </app-donation-spec-item>
            <app-donation-spec-item
              *ngIf="!onlySmall"
              (specChanged)="onSpecificationChange($event)"
              [count]="specification.medium"
              [key]="'medium'"
              itemTitle="Medium item"
              subtitle="1-person lift"
              description="Dining room and desk chairs, end tables, stools, small rugs and other light items that should be carried by one person."
              icon="md"
              fxFlex.xs="50"
              [leadId]="leadId"
              [activity]="activity.DONATION_SPEC_MD"
              [editable]="true"
            >
            </app-donation-spec-item>
            <app-donation-spec-item
              (specChanged)="onSpecificationChange($event)"
              [count]="specification.small"
              [key]="'small'"
              itemTitle="Bag or Box"
              [subtitle]="
                charity?.meta?.onlySmallItems
                  ? 'If more than 5 bags or boxes (eg. for 6 add 1 above)'
                  : '1-person lift'
              "
              description="Bags, or bins of household goods, or small items that are properly packed and closed if possible. These items must be able to be carried by one person!"
              icon="sm"
              fxFlex.xs="50"
              [leadId]="leadId"
              [activity]="activity.DONATION_SPEC_SM"
              [min]="onlySmall ? 5 : 0"
              [editable]="onlySmall ? false : true"
            >
            </app-donation-spec-item>
          </div>
          <div fxLayout="column" fxLayoutGap="0" fxLayoutGap.lt-md="20px">
            <app-donation-spec-item
              (specChanged)="onSpecificationChange($event)"
              [count]="specification.staircases"
              [key]="'staircases'"
              itemTitle="Staircases"
              subtitle="If no elevator"
              description="If your donations must be moved up or down stairs and no elevator is available, please select the number of staircases that must be traversed! Certain elevators may incur a separate fee."
              icon="staircases"
              fxFlex.xs="50"
              [leadId]="leadId"
              [activity]="activity.DONATION_SPEC_STAIR_CASES"
              [editable]="onlySmall ? false : true"
            >
            </app-donation-spec-item>
            <app-donation-spec-item
              *ngIf="!onlySmall"
              (specChanged)="onSpecificationChange($event)"
              [count]="specification.disassembly"
              [key]="'disassembly'"
              itemTitle="Disassembly"
              subtitle="Requiring tooling / upcharge items"
              description="Please add this for each item that requires tools for disassembly in order to remove!"
              icon="disassembly"
              fxFlex.xs="50"
              [leadId]="leadId"
              [activity]="activity.DONATION_SPEC_DISASSEMBLY"
              [editable]="true"
            >
            </app-donation-spec-item>
            <div class="divider"></div>
            <div
              class="submit-btn-wrapper"
              fxLayout="column"
              fxLayoutAlign="center center"
            >
              <div
                fxLayoutAlign="center center"
                [ngStyle]="{ 'margin-top.px': 20 }"
              >
                <button
                  id="step1Submission"
                  class="primary-btn filled-btn warn-btn"
                  (click)="saveLead()"
                  [ladda]="isSubmitting"
                  type="submit"
                >
                  Next Step
                </button>
              </div>
              <div
                *ngIf="flowService.optimizelyMarketingData['spec_page_review']"
                fxFlex
                [innerHTML]="
                  flowService.bypassSecurityTrustHtml(
                    flowService.optimizelyMarketingData['spec_page_review']
                  )
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" class="progress-wrapper">
      <app-create-progress
        fxFlex
        [step]="flowStep"
        [flowConfiguration]="flowConfiguration"
      ></app-create-progress>
    </div>
  </div>
</ng-template>
