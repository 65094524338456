import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ButtonActivity,
    ENVIRONMENT,
    Environment,
    Lead,
    PageActivity,
    Specification,
} from '@domains';
import { AppService } from '@donor/app.service';
import { CategorizeHelpDialogComponent } from '@donor/components/categorize-help-dialog/categorize-help-dialog.component';
import { InfoDialogComponent } from '@donor/components/info-dialog/info-dialog.component';
import { FlowService } from '@donor/screens/flow/donor-flow.service';
import { BaseFlowStepComponent } from '@donor/screens/flow/steps/base-flow-step.component';
import { DesignService, ResponsiveService } from '@rspl-ui';
import { take } from 'rxjs';

@Component({
  selector: 'app-flow-donation-details',
  templateUrl: './flow-donation-details.component.html',
  styleUrls: ['./flow-donation-details.component.scss'],
})
export class FlowDonationDetailsComponent
  extends BaseFlowStepComponent
  implements OnInit
{
  specification: Specification;
  activity = ButtonActivity;

  constructor(
    override flowService: FlowService,
    override appService: AppService,
    override router: Router,
    override route: ActivatedRoute,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    protected dialog: MatDialog,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      flowService,
      appService,
      router,
      route,
      designService,
      responsiveService,
      environment
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.specification = new Specification(this.flowService.lead.specification);
    if (this.specification?.isEmpty()) {
      if (this.route.snapshot.queryParams['disassembly'] === 'true') {
        this.specification.disassembly = 1;
      } else {
        this.specification.disassembly = 0;
      }
      if (this.route.snapshot.queryParams['stairs'] === 'true') {
        this.specification.staircases = 1;
      } else {
        this.specification.staircases = 0;
      }
    }
  }

  static override isCompleted(lead: Lead) {
    return !lead.specification.isEmpty();
  }

  override isValid(): boolean {
    return !this.specification.isEmpty();
  }

  override getFromValue(): Partial<Lead> {
    return {
      specification: this.specification,
    };
  }

  override showError(): void {
    this.appService
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.DONATION_SPEC_SUBMIT_ERROR
      )
      .pipe(take(1))
      .subscribe();
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'No Items',
        message:
          'You need to select at least one item to donate before proceeding to the next step',
        button: 'I understand.',
        icon: 'warning',
      },
    });
  }

  override saveButtonActivity(): ButtonActivity {
    return ButtonActivity.DONATION_SPEC_SUBMIT;
  }

  override pageVisitActivity(): PageActivity {
      return PageActivity.DONATION_SPEC_PAGE;
  }

  onSpecificationChange(e: any): void {
    Object.assign(this.specification || {}, e);
  }

  categorizeHelp() {
    this.dialog.open(CategorizeHelpDialogComponent, {
      ...(this.isSmallWindowSize
        ? {
            width: '900px',
            maxWidth: '90vw',
            height: '85vh',
            maxHeight: '85vh',
          }
        : {}),
      ...{
        panelClass: 'categorize-help-dialog',
        data: this.charity,
      },
    });
  }

  get onlySmall(): boolean {
    return !!this.charity?.meta?.onlySmallItems;
  }

  get specialIds(): string[] {
    return this.environment.special.onlySmall;
  }
}
