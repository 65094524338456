<ng-template #Priority>
  <div
    class="priority"
    fxLayout="column"
    fxFlex.lt-md="100"
    fxFlex
    [fxFlex.gt-sm]="charity.meta?.standardEnabled !== false ? 50 : 100"
    style="background: {{ charity.meta?.backgroundColorPrimary }}; color: {{
      charity.meta?.textColorPrimary
    }}"
  >
    <h1
      style="background: {{
        charity.meta?.titleBackgroundColorPrimary
      }}; color: {{ charity.meta?.titleTextColorPrimary }}"
    >
      {{ charity.meta?.titlePrimary }}
      <mat-icon
        svgIcon="prioritystar"
        style="background: {{ charity.meta?.stripeBackgroundColor }}"
      ></mat-icon>
      <span
        class="triangle-left"
        style="border-color: transparent {{
          charity.meta?.stripeBackgroundColor
        }} transparent transparent"
      ></span>
      <span
        class="triangle-right"
        style="border-color: {{
          charity.meta?.stripeBackgroundColor
        }} transparent transparent transparent"
      ></span>
    </h1>
    <div
      class="logo"
      fxLayout="row"
      fxLayoutGap="10px"
      style="background: {{ charity.meta?.logoBackgroundColorPrimary }}"
    >
      <div
        class="img img-priority-charity"
        fxFlex="40"
        fxLayoutAlign="end center"
      >
        <img
          [src]="charity.meta?.logo || charity.logo"
          [style]="
            'margin-right: ' +
            ((isDesktop
              ? charity.meta?.logoOffsetLeft || 0
              : charity.meta?.logoOffsetLeftMobile || 0) || 0) *
              -1 +
            'px; margin-top:' +
            (isDesktop
              ? charity.meta?.logoOffsetTop || 0
              : charity.meta?.logoOffsetTopMobile || 0) +
            'px;' +
            'height: calc(' +
            (isDesktop ? 55 : 37) +
            'px + ' +
            (isDesktop
              ? charity.meta?.logoSize || 0
              : charity.meta?.logoSizeMobile || 0) +
            'px);'
          "
        />
      </div>
      <span
        fxLayoutAlign="center center"
        fxFlex
        style="color: {{ charity.meta?.backgroundColorPrimary }}"
      >
        <mat-icon>add</mat-icon>
      </span>
      <div class="img" fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
        <div class="third-party-wrapper">
          <mat-icon svgIcon="rspl-new-logo"></mat-icon>
          <p
            *ngIf="charity.meta?.version === splitScreenVersions.V2"
            class="third-party"
          >
            3RD party veteran owned
          </p>
        </div>
      </div>
    </div>
    <ul
      *ngIf="
        charity.meta?.version === splitScreenVersions.V1 &&
        !((charity.meta?.textPrimary?.trim()?.length || 0) > 0)
      "
    >
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColorPrimary }}"
        ></span>
        Pickups as soon as 2 business days (Mon-Sat), subject to timeslot
        availability
      </li>
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColorPrimary }}"
        ></span>
        Can pick up from multiple floors
      </li>
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColorPrimary }}"
        ></span>
        Can disassemble furniture
      </li>
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColorPrimary }}"
        ></span>
        Items of ANY condition accepted
      </li>
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColorPrimary }}"
        ></span>
        Cost of the pickup for the charity is covered (Fee Based Service)
      </li>
    </ul>

    <ul
      *ngIf="
        charity.meta?.version === splitScreenVersions.V1 &&
        (charity.meta?.textPrimary?.trim()?.length || 0) > 0
      "
    >
      <li *ngFor="let el of textPrimaryValues">
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColorPrimary }}"
        ></span
        >{{ el }}
      </li>
    </ul>

    <ul *ngIf="charity.meta?.version === splitScreenVersions.V2">
      <li>
        <mat-icon svgIcon="ss-primary-floor"></mat-icon>
        Pickups from any floor
      </li>
      <li>
        <mat-icon svgIcon="ss-primary-disassembly"></mat-icon>
        Item disassembly and removal
      </li>
      <li>
        <mat-icon svgIcon="ss-primary-items"></mat-icon>
        All items are picked up for a fee
      </li>
      <li>
        <mat-icon svgIcon="ss-primary-time"></mat-icon>
        Donation pickups in 1-3 days
      </li>
    </ul>
    <p
      *ngIf="
        charity.meta?.version === splitScreenVersions.V2 &&
        (charity.meta?.textPrimary?.trim()?.length || 0) > 0
      "
      class="standard-additional-text"
      [innerHTML]="textPrimaryValue"
    ></p>
    <div class="btn-wrapper" fxLayout="row" fxFlex fxLayoutAlign="center end">
      <button
        mat-button
        class="btn-block"
        style="background: {{
          charity.meta?.btnBackgroundColorPrimary
        }}; color: {{ charity.meta?.btnTextColorPrimary }}; cursor: {{
          charity.meta?.primaryReady ? 'pointer' : 'default'
        }}"
        (click)="goToPrimary()"
        *ngIf="charity.meta?.primaryReady !== false"
      >
        <span>{{
          charity.meta?.buttonPrimary
            ? charity.meta?.buttonPrimary
            : 'Schedule Now!'
        }}</span>
      </button>
      <button
        mat-button
        class="btn-block"
        style="background: {{
          charity.meta?.btnBackgroundColorPrimary
        }}; color: {{ charity.meta?.btnTextColorPrimary }}; cursor: {{
          charity.meta?.primaryReady ? 'pointer' : 'default'
        }}; cursor: default"
        *ngIf="charity.meta?.primaryReady === false"
      >
        <span *ngIf="!charity.meta?.primaryReady">Coming Soon!</span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #Standard>
  <div
    class="free"
    [fxFlex]="isDesktop ? 50 : 100"
    fxLayout="column"
    *ngIf="charity.meta?.standardEnabled"
    style="background: {{ charity.meta?.backgroundColor }}; color: {{
      charity.meta?.textColor
    }}"
  >
    <h1
      style="background: {{ charity.meta?.titleBackgroundColor }}; color: {{
        charity.meta?.titleTextColor
      }}"
    >
      {{ charity.meta?.title }}
    </h1>
    <div
      class="logo"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="background: {{ charity.meta?.logoBackgroundColor }}"
    >
      <div class="img" fxLayoutAlign="center center">
        <img
          [src]="charity.meta?.logo || charity.logo"
          style="
        height: calc({{ isDesktop ? 55 : 37 }}px + {{
            (isDesktop
              ? charity.meta?.logoStandardSize
              : charity.meta?.logoStandardSizeMobile) || 0
          }}px);
        max-height: calc({{ isDesktop ? 55 : 37 }}px + {{
            (isDesktop
              ? charity.meta?.logoStandardSize
              : charity.meta?.logoStandardSizeMobile) || 0
          }}px);
        margin-top: {{
            isDesktop
              ? charity.meta?.logoStandardOffsetTop
              : charity.meta?.logoStandardOffsetTopMobile
          }}px;
        margin-right: {{
            ((isDesktop
              ? charity.meta?.logoStandardOffsetLeft
              : charity.meta?.logoStandardOffsetLeftMobile) || 0) * -1
          }}px;
      "
        />
      </div>
    </div>
    <ul
      *ngIf="
        charity.meta?.version === splitScreenVersions.V1 &&
        !((charity.meta?.text?.trim()?.length || 0) > 0)
      "
    >
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColor }}"
        ></span
        >Pickup services are free to you
      </li>
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColor }}"
        ></span
        >Ground floor disassembly only
      </li>
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColor }}"
        ></span
        >Items accepted based on condition
      </li>
      <li>
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColor }}"
        ></span
        >Scheduling based on availability
      </li>
      <li *ngIf="charity.meta?.showRestricedItems">
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColor }}"
        ></span>
        <a
          href="{{ charity.meta?.restricedItemsUrl }}"
          style="color: {{ charity.meta?.textColor }}"
          target="_blank"
          >{{ charity.meta?.restricedItemsText }}</a
        >
      </li>
    </ul>
    <ul
      *ngIf="
        charity.meta?.version === splitScreenVersions.V1 &&
        (charity.meta?.text?.trim()?.length || 0) > 0
      "
    >
      <li *ngFor="let el of textValues">
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColor }}"
        ></span
        >{{ el }}
      </li>
      <li *ngIf="charity.meta?.showRestricedItems">
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColor }}"
        ></span>
        <a
          href="{{ charity.meta?.restricedItemsUrl }}"
          style="color: {{ charity.meta?.textColor }}"
          target="_blank"
          >{{ charity.meta?.restricedItemsText }}</a
        >
      </li>
    </ul>
    <ul
      *ngIf="
        charity.meta?.version === splitScreenVersions.V2 &&
        screening &&
        charity.meta?.pickUp
      "
    >
      <li>
        <mat-icon
          [ngClass]="{ gray: screening.floor !== 'any' }"
          [svgIcon]="
            screening.floor === 'any' ? 'ss-primary-floor' : 'ss-standard-floor'
          "
        ></mat-icon>
        {{ screening.floor === 'curbside' ? 'Curbside pickups only' : '' }}
        {{
          screening.floor === 'first'
            ? 'Pickups from 1st floor only, no staircases'
            : ''
        }}
        {{
          screening.floor === 'second' ? 'Pickups from 2nd floor and below' : ''
        }}
        {{ screening.floor === 'any' ? 'Pickups from any floor' : '' }}
      </li>
      <li>
        <mat-icon
          [ngClass]="{ gray: !screening.disassembly }"
          [svgIcon]="
            screening.disassembly
              ? 'ss-primary-disassembly'
              : 'ss-standard-disassembly'
          "
        ></mat-icon>
        {{
          screening.disassembly
            ? 'Item disassembly and removal'
            : 'Self disassembly required'
        }}
      </li>
      <li>
        <mat-icon class="gray" svgIcon="ss-standard-items"></mat-icon>
        <div fxLayout="column">
          <label>Donatable items only </label
          ><a
            *ngIf="screening.restrictedItems.length > 0"
            (click)="showRestrictedItems()"
            >See restricted items list</a
          >
        </div>
      </li>
      <li>
        <mat-icon
          [ngClass]="{ gray: screening.leadTime !== '3 days or less' }"
          [svgIcon]="
            screening.leadTime === '3 days or less'
              ? 'ss-primary-time'
              : 'ss-standard-time'
          "
        ></mat-icon>
        Donation pickups in {{ screening.leadTime }}
      </li>
    </ul>
    <p
      *ngIf="
        charity.meta?.pickUp &&
        charity.meta?.version === splitScreenVersions.V2 &&
        textValue
      "
      class="standard-additional-text"
      [innerHTML]="textValue"
    ></p>
    <ul
      *ngIf="
        !charity.meta?.pickUp &&
        charity.meta?.version === splitScreenVersions.V2 &&
        textValues.length > 0
      "
    >
      <li *ngFor="let el of textValues">
        <span
          class="bullet"
          style="background: {{ charity.meta?.bulletColor }}"
        ></span
        >{{ el }}
      </li>
    </ul>
    <p
      *ngIf="
        charity.meta?.version === splitScreenVersions.V2 &&
        charity.meta?.showRestricedItems
      "
      class="donation-guidlines"
      style="color: {{ charity.meta?.textColor }}"
    >
      Please see our
      <a href="{{ charity.meta?.restricedItemsUrl }}" target="_blank"
        >Donation Guidelines</a
      >
      before visiting one of our donation centers.
    </p>
    <div class="btn-wrapper" fxLayout="row" fxFlex fxLayoutAlign="center end">
      <button
        mat-button
        class="btn-block"
        (click)="goToFree()"
        *ngIf="charity.meta?.standardReady !== false"
        style="background: {{ charity.meta?.btnBackgroundColor }}; color: {{
          charity.meta?.btnTextColor
        }}"
      >
        <span>{{
          charity.meta?.button ? charity.meta?.button : 'Request Approval'
        }}</span>
      </button>
      <button
        mat-button
        class="btn-block"
        *ngIf="charity.meta?.standardReady === false"
        style="background: {{ charity.meta?.btnBackgroundColor }}; color: {{
          charity.meta?.btnTextColor
        }}; cursor: default"
      >
        <span>Coming Soon!</span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #RestrictedItems>
  <h2 mat-dialog-title style="margin: 0">Restricted Items</h2>
  <div mat-dialog-content>
    <p>Following items are not accepted by charity:</p>
    <ul>
      <li *ngFor="let item of screening?.restrictedItems">
        {{ item }}
      </li>
    </ul>
  </div>
  <div mat-dialog-actions>
    <button
      class="primary-btn filled-btn"
      (click)="restrictedItemsDialog?.close()"
      cdkFocusInitial
    >
      Ok
    </button>
  </div>
</ng-template>
<div class="split-screen" *ngIf="charity && screening && showScreening">
  <app-donor-screening
    (close)="close.next($event)"
    (back)="showScreening = false"
    [screening]="screening"
    [charity]="charity"
    [leadId]="leadId"
  ></app-donor-screening>
</div>
<div class="split-screen" *ngIf="charity && !showScreening">
  <div class="space" fxLayoutAlign="center center" fxLayout="column">
    <div
      fxLayoutGap="30px"
      class="cards  {{ charity.meta?.version }}"
      fxLayout.lt-md="column"
      fxLayout.gt-sm="row"
    >
      <ng-container
        [ngTemplateOutlet]="charity.meta?.reverse ? Standard : Priority"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="charity.meta?.reverse ? Priority : Standard"
      ></ng-container>
    </div>
  </div>
  <div fxLayout="row" class="social" fxLayoutGap="20px" fxLayoutAlign="end end">
    <a
      href="https://www.facebook.com/resupplyapp/?eid=ARDseOOUc71V0lp5-0QTY42vLkLjbxneImEoXfK9y7BqflDTH3-sM7O9fvG9ED82tA4ZmakojbSaRboA"
    >
      <mat-icon svgIcon="facebook"></mat-icon>
    </a>
    <a href="https://www.instagram.com/resupplyme/">
      <mat-icon svgIcon="instagram"></mat-icon>
    </a>
    <a href="https://www.linkedin.com/company/resupplyme/?viewAsMember=true">
      <mat-icon svgIcon="linkedin"></mat-icon>
    </a>
  </div>
</div>
